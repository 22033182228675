<template>
  <div class="main-accountLogon">
      <div class="views">
          <div class="title">账号登录</div>
          <div class="zh-title">账号</div>
          <input class="input-title" type="text" v-model="account" placeholder="请输入账号">
          <div class="mm-title">密码</div>
          <input class="input-title" type="password" v-model="pwd" placeholder="请输入密码">
          <div class="login" :style="{backgroundColor:isSkin}" @click.stop="loginMethod()">登录</div>
          <div class="wj-title" @click.stop="forgetPwdMethod()">忘记密码</div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
    data() {
        return {
            account:'',
            pwd:''
        }
    },
    created(){
        this.$store.commit('changClick',1)
        var skin = sessionStorage.getItem('Skin')
        var zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
        if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        }
    },
    methods:{
        forgetPwdMethod(){
            this.$router.push('/forgetPwd');
            this.$store.commit('changClick',1)
        },
        loginMethod(){
            var that = this
            if (that.account == '') {
                Message({
                    showClose: true,
                    message: '请输入账号！',
                    type: 'error'
                })
                return
            }
            if (that.pwd == '') {
                Message({
                    showClose: true,
                    message: '请输入密码！',
                    type: 'error'
                })
                return
            }
            that.$api.getAccountLogin({
                mobile:that.account,
                password:that.pwd
            }).then(res=>{
                if (res.data.code == 0) {
                    sessionStorage.setItem('token',res.data.data.token)
                    sessionStorage.setItem('userHeadImg',res.data.data.userInfo.headImgUrl)
                    sessionStorage.setItem('userName',res.data.data.userInfo.userName)
                    sessionStorage.setItem('userNickName',res.data.data.userInfo.nickname)
                    sessionStorage.setItem('Teachers',res.data.data.userInfo.identity)
                    sessionStorage.setItem('bindWechat',res.data.data.userInfo.bindWechat)
                    Message({
                        showClose: true,
                        message: '登录成功！',
                        type: 'success'
                    })
                    setTimeout(()=>{
                        this.$router.push('/');
                        this.$store.commit('changClick',0)
                        this.$store.commit('changLogin',1)
                        this.$store.commit('changNickname',res.data.data.userInfo.nickname)
                        this.$store.commit('changHeadImgUrl',res.data.data.userInfo.headImgUrl)
                        this.$store.commit('changTeachers',res.data.data.userInfo.identity)
                    },2000)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.main-accountLogon{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .views{
        width: 427px;
        height: 396px;
        background: #FCFCFC;
        margin-top: 50px;
        margin-bottom: 175px;
        .title{
            font-size: 24px;
            line-height: 34px;
            color: #333333;
            margin-left: 20px;
            margin-top: 30px;
        }
        .zh-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 40px;
        }
        .mm-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 20px;
        }
        .login{
            width: 387px;
            height: 42px;
            margin-left: 20px;
            margin-top: 40px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
        }
        .wj-title{
            width: 60px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #666;
            cursor: pointer;
            margin-left: 347px;
            margin-top: 20px;
        }
    }
}
.input-title{
    width: 387px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none; //去点击蓝色边框
    padding-left: 10px;
    padding-right: 30px;
    font-size: 15px;
    line-height: 40px;
    color: #666666;
    margin-left: 20px;
    margin-top: 10px;
}
</style>